import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import GtrNewAttendeeFormGroup from '@/modules/level-two/views/event/attendees/create-attendee/new-attendee-group.form.vue'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Container from 'typedi'
import { Component, Prop } from 'vue-property-decorator'

interface TableHeaderItem {
  text: string;
  value: string;
  sortable: boolean;
  width?: string;
}

interface TableRow {
  [index: string]: string | number | boolean;
}

@Component({
  name: 'GtrGroupTab',
  components: {
    'gtr-new-attendee-form-group': GtrNewAttendeeFormGroup
  }
})
export default class GtrAGroupTab extends GtrSuper {
  @Prop({ required: true, type: Boolean, default: false })
  a2zImport!: boolean;

  event_uuid = ''

  attendee_uuid = ''

  data () {
    return {
      group_uuid: null,
      custom_fields: [],
      group: {
        loading: false
      },
      forms: {
        showNewAttendeeForm: false,
        showNewCustomFieldForm: false
      },
      groupTableHeaders: [
        {
          text: '',
          value: 'parent',
          sortable: false
        }, {
          text: '',
          sortable: false,
          value: 'profile_photo'
        }, {
          text: 'First Name',
          sortable: false,
          value: 'first_name'
        }, {
          text: 'Last Name',
          sortable: false,
          value: 'last_name'
        }, {
          text: 'Email',
          sortable: false,
          value: 'email'
        }, {
          text: 'Status',
          sortable: false,
          value: 'status'
        }, {
          text: '',
          value: 'actions',
          sortable: false
        }
      ]
    }
  }

  mounted () {
    this.fetchCustomFields()
    this.$data.group_uuid = this.$store.state.registration.participantsGroup.uuid
  }

  created () {
    this.event_uuid = this.$route.params.event_uuid
    this.attendee_uuid = this.$route.params.attendee_uuid
  }

  get participantName () {
    let name = ''
    const registration_data = this.$store.state.registration?.registration?.condensed_data
    if (registration_data) {
      name = registration_data.first_name + ' ' + registration_data.last_name
    }
    return name
  }

  get groupTableRows () {
    this.$data.group.loading = true
    const rows: TableRow[] = []

    try {
      const groupParticipants = this.$store.state.registration.participantsGroup.participant_group_participants

      if (groupParticipants?.length) {
        groupParticipants.forEach(participant => {
          if (participant.participant) {
            const row: TableRow = {}
            row.registrant = participant.parent
            row.participant_uuid = participant.participant.uuid
            row.profile_photo = participant.participant.participant_data.profile_photo
            row.first_name = participant.participant.participant_data.first_name
            row.last_name = participant.participant.participant_data.last_name
            row.email = participant.participant.participant_data.email
            row.status = participant.participant.participant_data.status
            rows.push(row)
          }
        })
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
    }

    this.$data.group.loading = false
    return rows
  }

  handleParticipantView (item: any) {
    this.$router.push({ name: 'level-two.event.attendees.edit', params: { event_uuid: this.event_uuid, attendee_uuid: item.participant_uuid } })
    window.location.reload()
  }

  async handleRemoveParticipantFromGroup (item: any) {
    try {
      const payload = {
        event_uuid: this.event_uuid,
        group_uuid: this.$store.state.registration.participantsGroup.uuid,
        participant_uuid: item.participant_uuid
      }
      await this.$store.dispatch('registration/removeParticipantFromGroup', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  handleShowNewAttendeeForm () {
    this.$data.forms.showNewAttendeeForm = true
  }

  handleCloseNewAttendeeForm () {
    this.$data.forms.showNewAttendeeForm = false
  }

  async handleAddNewAttendee () {
    await this.fetchParticipantsGroup()
  }

  private async fetchParticipantsGroup () {
    try {
      const payload = {
        event_uuid: this.event_uuid,
        group_uuid: this.$store.state.registration.participantsGroup.uuid
      }
      await this.$store.dispatch('registration/getParticipantsGroup', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  async fetchCustomFields () {
    try {
      this.$data.loading = true
      const temp_custom_fields = await this.$store.dispatch('event/loadCustomFields', { event_uuid: this.$route.params.event_uuid })
      this.$data.custom_fields = []
      temp_custom_fields.forEach(item => {
        if (item.field.includes('custom_')) {
          this.$data.custom_fields.push(item)
        }
      })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }
}
