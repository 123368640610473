import { mapState } from 'vuex'
import { Component, Watch } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import GtrAddTransactionForm from '../../forms/add-transaction.form.vue'
import GtrRefundForm from '../../forms/refund.form.vue'
import GtrManagePromoCodesForm from '../../forms/manage-promo-codes.form.vue'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Component({
  name: 'GtrTransactionsTab',
  components: {
    'gtr-new-transaction-form': GtrAddTransactionForm,
    'gtr-refund-form': GtrRefundForm,
    'gtr-manage-promo-codes-form': GtrManagePromoCodesForm
  },
  computed: {
    ...mapState('event', ['eventAllContent'])
  }
})
export default class GtrTransactionsTab extends GtrSuper {
  event_uuid = '';

  attendee_uuid = '';

  showRefundModal = false;

  transactionToRefund: Record<string, any> = {};

  voiding = false;

  refunding = false;

  data () {
    return {
      event_all_content: null,
      payment_processor_currency: null,
      transactions: {
        loading: false,
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'uuid'
          },
          {
            text: 'Date',
            align: 'start',
            sortable: false,
            value: 'created_at'
          },
          {
            text: 'Transaction ID',
            align: 'start',
            sortable: false,
            value: 'external_id'
          },
          {
            text: 'Type',
            align: 'start',
            sortable: false,
            value: 'payment_type'
          },
          {
            text: 'Amount',
            align: 'start',
            sortable: false,
            value: 'amount'
          },
          {
            text: 'Line items',
            align: 'start',
            sortable: false,
            value: 'line_items'
          },
          {
            text: 'Last 4',
            align: 'start',
            sortable: false,
            value: 'last_four'
          },
          {
            text: 'Expiration',
            align: 'start',
            sortable: false,
            value: 'expdate'
          },
          {
            text: 'Result',
            align: 'start',
            sortable: false,
            value: 'result'
          },
          {
            text: 'Refund',
            value: 'refund',
            searchable: false,
            sortable: false
          },
          {
            text: 'Void',
            value: 'void',
            searchable: false,
            sortable: false,
            width: '90px'
          }
        ]
      },
      showAddTransactionForm: false,
      showManagePromoCodesForm: false
    }
  }

  @Watch('eventAllContent', { immediate: true })
  onEventAllContentChange (value: any) {
    if (value) {
      this.$data.event_all_content = value
      this.$data.payment_processor_currency = this.$data.event_all_content.payment_processor_currency
    }
  }

  created () {
    this.event_uuid = this.$route.params.event_uuid
    this.attendee_uuid = this.$route.params.attendee_uuid
  }

  private async fetchRegistrationTransactions () {
    try {
      this.$data.transactions.loading = true
      const payload = {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid,
        queryParam: ''
      }
      await this.$store.dispatch('registration/getRegistrationTransactions', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.transactions.loading = false
    }
  }

  get registrationTransactions () {
    if (Array.isArray(this.$store.state.registration.registrationTransactions.data)) {
      const results: any[] = []
      const items = this.$store.state.registration.registrationTransactions.data
      for (const itemKey in items) {
        if (!items[itemKey].lrorders_transaction && !items[itemKey].parked) {
          results.push(items[itemKey])
        }
      }
      return results
    } else {
      return []
    }
  }

  get isOrdersEnabled (): boolean {
    return this.$store.state.event.eventModules && this.$store.state.event.eventModules.LEADS && this.$store.state.event.eventModules.LEADS.enabled
  }

  get lrTransactions () {
    if (Array.isArray(this.$store.state.registration.registrationTransactions.data)) {
      const results: any[] = []
      const items = this.$store.state.registration.registrationTransactions.data
      for (const itemKey in items) {
        if (items[itemKey].lrorders_transaction) {
          results.push(items[itemKey])
        }
      }
      return results
    } else {
      return []
    }
  }

  get registration () {
    return this.$store.state.registration.registration
  }

  get payment_processor_widget_id (): string {
    return this.$store.state.payment.payment_processor_widget['0'] || ''
  }

  handleShowRefundModal () {
    this.showRefundModal = true
  }

  handleCloseRefundModal () {
    this.showRefundModal = false
  }

  startRefund (item: Record<string, any>): void {
    // set the transaction to refund.
    this.transactionToRefund = item
    // display the modal.
    this.showRefundModal = true
  }

  async compRegistration () {
    if (confirm('Are you sure you want to comp this participant')) {
      await this.$store.dispatch('registration/compRegistration', { event_uuid: this.event_uuid, participant_uuid: this.attendee_uuid, group_uuid: this.registration.participant_group_uuid, comped: true })
      Container.get(Notification).success('Registration successfully comped.')
      await this.$store.dispatch('registration/getRegistration', { event_uuid: this.event_uuid, participant_uuid: this.attendee_uuid })
    }
  }

  async uncompRegistration () {
    if (confirm('Are you sure you want to remove the comp for this participant')) {
      await this.$store.dispatch('registration/compRegistration', { event_uuid: this.event_uuid, participant_uuid: this.attendee_uuid, group_uuid: this.registration.participant_group_uuid, comped: false })
      Container.get(Notification).success('Registration successfully un-comped.')
      await this.$store.dispatch('registration/getRegistration', { event_uuid: this.event_uuid, participant_uuid: this.attendee_uuid })
    }
  }

  handleRefund (refundData: Record<string, any>): void {
    try {
      this.refunding = true
      // reset transactionToRefund
      this.transactionToRefund = {}
      // package a payload for the BE.
      const payload: {
        event_uuid: string;
        participant_uuid: string;
        data: Record<string, any>;
      } = {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid,
        data: refundData
      }
      // hand off payload to the payment store.
      this.$store.dispatch('payment/postRefund', payload)
    } catch (err) {
      Container.get(ErrorHandlerService).error('There was an issue with your refund') // should we update error message to explain next steps to take?
    } finally {
      this.refunding = false
    }
  }

  async handleAction () {
    if (this.registration.participant_group_uuid) {
      const payload = {
        event_uuid: this.event_uuid,
        group_uuid: this.registration.participant_group_uuid
      }
      await this.$store.dispatch('registration/getGroupFees', payload)
    } else {
      this.$store.dispatch('registration/getRegistrationFees', {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid
      })
      this.$store.dispatch('registration/getLRFees', {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid
      })
    }
  }

  handleShowAddTransactionForm () {
    this.$data.showAddTransactionForm = true
  }

  async handleCloseAddTransactionForm () {
    this.$data.showAddTransactionForm = false
    await this.fetchRegistrationTransactions()
  }

  async voidTransaction (transaction) {
    try {
      this.$data.transactions.loading = true
      this.voiding = true
      const data = {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid,
        widget_id: this.payment_processor_widget_id,
        transaction_id: transaction.uuid,
        amount: transaction.amount,
        payment_type: transaction.payment_type + ' void'
      }
      if (!transaction.widget_id) {
        await this.$store.dispatch('payment/voidNonWidgetTransaction', { event_uuid: this.event_uuid, participant_uuid: this.attendee_uuid, data })
      } else {
        await this.$store.dispatch('payment/voidPaymentProcessorTransaction', { event_uuid: this.event_uuid, participant_uuid: this.attendee_uuid, widget_id: transaction.widget_id, data })
      }
      Container.get(Notification).success('Transaction successfully voided.')
      await this.fetchRegistrationTransactions()
      /**
       * If the current reg is part of a group fetch group fees.
       * Otherwise just the registrationFees.
       */
      if (this.registration.participant_group_uuid) {
        await this.$store.dispatch('registration/getGroupFees', {
          event_uuid: this.event_uuid,
          group_uuid: this.registration.participant_group_uuid
        })
      } else {
        this.$store.dispatch('registration/getRegistrationFees', {
          event_uuid: this.event_uuid,
          participant_uuid: this.attendee_uuid
        })
        this.$store.dispatch('registration/getLRFees', {
          event_uuid: this.event_uuid,
          participant_uuid: this.attendee_uuid
        })
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.transactions.loading = false
      this.voiding = false
    }
  }

  handleShowManagePromoCodesForm () {
    this.$data.showManagePromoCodesForm = true
  }

  handleCloseManagePromoCodesForm () {
    this.$data.showManagePromoCodesForm = false
  }

  get showCompRegistration () {
    return this.$store.state.registration.registrationFees && this.$store.state.registration.registrationFees.balance > 0
  }

  get showUncompRegistration () {
    return this.$store.state.registration?.registration?.participant_data && this.$store.state.registration.registration.participant_data?.comped
  }
}
