import { Component, Watch } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Container from 'typedi'
import { mapGetters, mapState } from 'vuex'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'

@Component({
  name: 'GtrSessionsTab',
  computed: {
    ...mapState('sessions', ['sessionsByParticipant', 'participant_access', 'sessions'])
  }
})
export default class GtrSessionsTab extends GtrSuper {
  event_uuid = ''

  attendee_uuid = ''

  data () {
    return {
      addAccessSession: null,
      date_scanned: null,
      sessionsRes: [],
      addScanSession: null,
      allSessions: [],
      access: [],
      headers: [{
        text: 'Session',
        align: 'start',
        sortable: false,
        value: 'session_name'
      },
      {
        text: 'Check In',
        align: 'start',
        sortable: false,
        value: 'min_date_scanned'
      },
      {
        text: 'Check Out',
        align: 'start',
        sortable: false,
        value: 'max_date_scanned'
      },
      {
        text: 'Time In Room (Minutes)',
        align: 'start',
        sortable: false,
        value: 'time_in_room'
      }],
      access_headers: [{
        text: 'Session',
        align: 'start',
        sortable: false,
        value: 'session_name'
      },
      {
        text: 'Access',
        align: 'start',
        sortable: false,
        value: 'access_level'
      }]
    }
  }

  created () {
    this.event_uuid = this.$route.params.event_uuid
    this.attendee_uuid = this.$route.params.attendee_uuid
  }

  mounted () {
    try {
      this.fetchSessionsByParticipant()
      this.fetchAccessByParticipant()
      this.fetchSessions()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
    }
  }

  @Watch('sessions', { immediate: true })
  onSessions (value: any) {
    if (value) {
      this.$data.allSessions = value
    }
  }

  @Watch('participant_access', { immediate: true })
  onSessionAccess (value: any) {
    if (value) {
      const access: any = []
      for (const i in value) {
        const item = value[i]
        item.session_name = item.session.name
        access.push(item)
      }
      this.$data.access = access
    }
  }

  async addScan () {
    try {
      this.$data.submitting = true
      const payload: any = {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid,
        session_uuid: this.$data.addScanSession,
        data: { scan_type: 'Admin', device_type: 'MANUAL', date_scanned: this.$data.date_scanned }
      }
      await this.$store.dispatch('sessions/addAttendance', payload)
      Container.get(Notification).success('Scan successfully created.')
      this.fetchSessionsByParticipant()
      this.$data.addScanSession = null
      this.$data.date_scanned = null
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.submitting = false
    }
  }

  @Watch('sessionsByParticipant', { immediate: true })
  onSessionsByParticipant (value: any) {
    if (value) {
      const groupedSessions = Object.values(
        value.reduce((acc: Record<string, any>, session: any) => {
          const uuid = session.session.uuid
          const dateScanned = new Date(session.date_scanned) // Convert string to Date

          if (!acc[uuid]) {
            acc[uuid] = {
              session_name: session.session.name,
              min_date_scanned: dateScanned,
              max_date_scanned: dateScanned
            }
          } else {
            acc[uuid].min_date_scanned = acc[uuid].min_date_scanned < dateScanned ? acc[uuid].min_date_scanned : dateScanned
            acc[uuid].max_date_scanned = acc[uuid].max_date_scanned > dateScanned ? acc[uuid].max_date_scanned : dateScanned
          }
          return acc
        }, {})
      ).map((session: any) => {
        const timeInRoom = (session.max_date_scanned - session.min_date_scanned) / 60000 // Convert ms to minutes
        return {
          session_name: session.session_name,
          min_date_scanned: session.min_date_scanned.toISOString(), // Convert back to string if needed
          max_date_scanned: session.min_date_scanned !== session.max_date_scanned ? session.max_date_scanned.toISOString() : '',
          time_in_room: timeInRoom > 0 ? Math.round(timeInRoom) : 0 // Round to nearest minute
        }
      })
      this.$data.sessionsRes = groupedSessions
    }
  }

  private async fetchSessionsByParticipant () {
    try {
      const payload = {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid
      }
      await this.$store.dispatch('sessions/getSessionsByParticipant', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchSessions () {
    try {
      const payload = {
        event_uuid: this.event_uuid
      }
      await this.$store.dispatch('sessions/fetchSessions', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchAccessByParticipant () {
    try {
      const payload = {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid
      }
      await this.$store.dispatch('sessions/getAccessForParticipant', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  get allAnalytics () {
    const allAnalytics: any = []

    try {
      const registrationAnalytics = this.$store.state.registration.registrationAnalytics
      for (const itemIndex in registrationAnalytics.data) {
        const item = registrationAnalytics.data[itemIndex]
        for (const analyticDataIndex in item.analytics) {
          const analyticData = item.analytics[analyticDataIndex]
          analyticData.unique_id = item.unique_id
          allAnalytics.push(analyticData)
        }
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
    }

    return allAnalytics
  }

  get allSessionsNoAccess () {
    const allSessions = this.$data.allSessions
    const access = this.$data.access
    const sessions: any = []
    for (const session_index in allSessions) {
      const session = allSessions[session_index]
      const accessEntry = access.filter(i => i.session.uuid === session.uuid)
      if (accessEntry.length === 0) {
        sessions.push(session)
      }
    }
    return sessions
  }

  async addAccess () {
    try {
      const payload = {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid,
        data: { session_uuid: this.$data.addAccessSession }
      }
      await this.$store.dispatch('sessions/giveAccess', payload)
      await this.fetchAccessByParticipant()
      Container.get(Notification).success('Added access successfully.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }
}
