import { render, staticRenderFns } from "./group-tab.view.vue?vue&type=template&id=172839c8&"
import script from "./group-tab.view.ts?vue&type=script&lang=ts&"
export * from "./group-tab.view.ts?vue&type=script&lang=ts&"
import style0 from "./group-tab.view.vue?vue&type=style&index=0&id=172839c8&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports