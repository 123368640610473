import { Component, Ref, Watch } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { mapState } from 'vuex'
import BadgesMixin from '@/modules/level-two/views/event/modules/badges/mixins/badges.mixin'
import { mixins } from 'vue-class-component'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import GtrBadgePreview from '@/modules/common/components/ui-core/gtr-badge-preview/gtr-badge-preview.vue'

@Component({
  name: 'GtrBadgesTab',
  components: {
    'gtr-badge-preview': GtrBadgePreview
  },
  computed: {
    ...mapState('badges', ['badges', 'participantBadge'])
  }
})

export default class GtrBadgesTab extends mixins(GtrSuper, BadgesMixin) {
  event_uuid = ''

  attendee_uuid = ''

  participantBadge!: any

  @Ref()
  readonly svgPreview!: InstanceType<typeof HTMLDivElement>

  data () {
    return {
      loading: false,
      dataPreview: {}
    }
  }

  created () {
    this.event_uuid = this.$route.params.event_uuid
    this.attendee_uuid = this.$route.params.attendee_uuid
  }

  mounted () {
    this.fetchAttendeeBadge()
    if (this.attendeeSvg) {
      this.$data.dataPreview = this.buildDataPreview(this.attendeeSvg, this.attendeeData, this.attendeeOptions)
    }
  }

  async fetchAttendeeBadge (): Promise<void> {
    try {
      this.$data.loading = true
      await this.$store.dispatch('badges/fetchAttendeeBadgePNG', {
        event_uuid: this.event_uuid,
        data: {
          type: '5384',
          participant_uuids: [this.attendee_uuid],
          asPNG: true
        }
      })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  @Watch('participantBadge', { immediate: true })
  onParticipantBadgeChange (value: any) {
    if (value) {
      this.$data.loading = false
    }
  }

  get pngWidth (): any {
    if (this.svgPreview) {
      const svg = this.svgPreview.firstChild
      const width = (svg as SVGElement).getAttribute('width')
      return parseInt(width ?? '384')
    }
    return 384
  }

  get badgeURL (): string {
    return this.participantBadge?.result_data?.url ?? ''
  }

  get activeBadges () {
    return this.$store.state.badges.badges.filter(badge => badge.active)
  }

  get attendeeRegType () {
    return this.$store.state.registration.registration.registration_type
  }

  get defaultBadgeObj () {
    if (this.activeBadges) {
      for (let i = 0; i < this.activeBadges.length; i++) {
        if (this.activeBadges[i].registration_type === '_default') {
          return this.activeBadges[i]
        }
      }
    }
    return {}
  }

  get attendeeSvg () {
    if (this.activeBadges) {
      for (let i = 0; i < this.activeBadges.length; i++) {
        if (this.attendeeRegType) {
          if (this.activeBadges[i].registration_type === this.attendeeRegType) {
            return this.activeBadges[i].svg
          }
        } else if (!this.attendeeRegType) {
          return this.defaultBadgeObj.svg
        }
      }
    }
    return this.defaultBadgeObj.svg
  }

  get attendeeData () {
    return this.$store.state.registration.registration.participant_data
  }

  get attendeeOptions () {
    return this.$store.state.registration.registration.selected_options
  }
}
