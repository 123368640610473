import { Component, Watch } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { mapGetters, mapState } from 'vuex'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import moment from 'moment/moment'

@Component({
  name: 'GtrFieldsTab',
  computed: {
    ...mapState('event', ['participant_fields', 'eventModules', 'event']),
    ...mapState('registration', ['registrationTransactions']),
    ...mapState('surveys', ['participantSurveys']),
    ...mapState('setting', ['settings']),
    ...mapGetters('surveys', ['completedParticipantSurveys'])
  }
})
export default class GtrFieldsTab extends GtrSuper {
  participant_fields!: any

  eventModules!: any

  event_uuid = ''

  event!: Record<string, any>

  attendee_uuid = ''

  settings!: any

  participantSurveys!: Record<string, any>;

  completedParticipantSurveys!: Record<string, any>;

  registrationTransactions!: Record<string, any>;

  data () {
    return {
      displayAdvanced: true,
      advanced_search: '',
      fields: {
        items: []
      },
      advanced_fields: {
        items: [],
        page: 1,
        itemsPerPage: 9,
        itemsPerPageArray: [3, 9, 12, 'All'],
        numberOfPages: 6
      },
      sendEmailModalOpen: false,
      sendCancelEmailModalOpen: false
    }
  }

  /*
   * LIFECYCLES
   */

  created () {
    this.event_uuid = this.$route.params.event_uuid
    this.attendee_uuid = this.$route.params.attendee_uuid
  }

  async mounted () {
    await this.fetchSettings()
    await this.fetchEvent()
    await this.fetchParticipantFields()
  }

  get participantCredits () {
    // loop through completed surveys and sum the credits
    const session_surveys = this.completedParticipantSurveys.filter(survey => survey.session)

    const credits = session_surveys.reduce((acc, survey) => {
      return acc + parseInt(survey.session.credits)
    }, 0)

    const alt_credits = session_surveys.reduce((acc, survey) => {
      return acc + parseInt(survey.session.alt_credits)
    }, 0)

    return {
      credits: isNaN(credits) ? 0 : credits,
      alt_credits: isNaN(alt_credits) ? 0 : alt_credits
    }
  }

  /*
   * DISPATCH
   */

  private async fetchEvent () {
    try {
      await this.$store.dispatch('event/fetchEvent', this.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchParticipantFields () {
    try {
      await this.$store.dispatch('event/getParticipantFields', this.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchGroupFees () {
    try {
      const payload = {
        event_uuid: this.event_uuid,
        group_uuid: this.$store.state.registration.registration.participant_group_uuid
      }
      await this.$store.dispatch('registration/getGroupFees', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchSettings () {
    try {
      await this.$store.dispatch('setting/getSettings', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      if (error.data.error_code !== 'CONTENT_NOT_FOUND') {
        this.$store.commit('setting/SET_SETTINGS', [])
        Container.get(ErrorHandlerService).error(error)
      }
    }
  }

  openEmailModalOrSubmit () {
    if (this.eventModules.REGISTRATION.enabled) {
      const fields = this.$data.advanced_fields.items
      for (let i = 0; i < fields.length; i++) {
        if (fields[i].field === 'status') {
          if (fields[i].value === 'Complete') {
            this.$data.sendEmailModalOpen = true
          }
          if (fields[i].value === 'Canceled') {
            this.$data.sendCancelEmailModalOpen = true
          }
          break
        }
      }
      if (!this.$data.sendEmailModalOpen && !this.$data.sendCancelEmailModalOpen) {
        this.editRegistrationFields()
      }
    } else {
      this.editRegistrationFields(false)
    }
  }

  closeEmailModalAndDoNotSendEmail () {
    this.$data.sendEmailModalOpen = false
    this.editRegistrationFields(false)
  }

  closeEmailModalAndSendEmail () {
    this.$data.sendEmailModalOpen = false
    this.editRegistrationFields(true)
  }

  closeCancelEmailModalAndDoNotSendEmail () {
    this.$data.sendCancelEmailModalOpen = false
    this.editRegistrationFields(false, false)
  }

  closeCancelEmailModalAndSendEmail () {
    this.$data.sendCancelEmailModalOpen = false
    this.editRegistrationFields(false, true)
  }

  async editRegistrationFields (sendEmail?: boolean, sendCancelEmail?: boolean) {
    try {
      /**
       * Convert current_registration back to an object for saving in the database
       */
      const data: any = {}
      this.currentRegistration.forEach(element => {
        const currentField = element.field
        const currentValue = element.value
        data[currentField] = currentValue
      })
      if (this.transactions.length && data?.status === 'Deleted') {
        Container.get(Notification).error('Record can\'t be deleted due to the associated transaction.')
        return
      }
      const payload = {
        event_uuid: this.event_uuid,
        registration_uuid: this.attendee_uuid,
        data,
        send_email_override: sendEmail,
        send_cancel_email: sendCancelEmail
      }
      await this.$store.dispatch('registration/editRegistration', payload)
      if (this.isGroup) {
        await this.fetchGroupFees()
      }
      Container.get(Notification).success('Participant successfully edited.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  /*
   * METHODS
   */

  toggleDisplayAdvanced () {
    this.$data.displayAdvanced = !this.$data.displayAdvanced
  }

  updateItemsPerPageAdvanced (number) {
    let tempArr = this.currentRegistration
    tempArr = tempArr.filter(item => !this.fieldsOnFormOrCustom.includes(item.field))
    this.$data.advanced_fields.itemsPerPage = number === 'All' ? tempArr.length : number
    this.$data.fields.page = 1
  }

  nextPageAdvanced () {
    if (this.$data.advanced_fields.page + 1 <= this.$data.advanced_fields.numberOfPages) this.$data.advanced_fields.page += 1
  }

  formerPageAdvanced () {
    if (this.$data.advanced_fields.page - 1 >= 1) this.$data.advanced_fields.page -= 1
  }

  /**
   *
   * @param items
   * @param showOnlyFormFields
   * @returns Returns an array of objects
   */
  filterFields (items: any, showOnlyFormFields: boolean): Array<Record<string, any>> {
    return showOnlyFormFields ? items.filter(item => item && this.fieldsOnForm.includes(item.field)) : items.filter(item => item && !this.fieldsOnForm.includes(item.field))
  }

  /**
   *
   * @param items
   * @param showOnlyFormFields
   * @returns Returns an array of objects
   */
  customFields (items: any, showOnlyFormFields: boolean): Array<Record<string, any>> {
    const tempArr = items
    if (showOnlyFormFields) {
      return tempArr.filter(item => item && this.fieldsOnFormOrCustom.includes(item.field))
    }
    return tempArr.filter(item => item && !this.fieldsOnFormOrCustom.includes(item.field))
  }

  checkForCancel (value: string) {
    const dateCanceledField = this.$data.advanced_fields.items.find((item: any) => item.label === 'Date Canceled')
    if (value === 'Canceled') {
      dateCanceledField.value = new Date().toLocaleTimeString('en-US', {
        hour12: true,
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: this.event.timezone
      }).replaceAll('/', '-')
    } else {
      dateCanceledField.value = ''
    }
  }

  formattedDateTimeField (value: string): string {
    return value ? this.$options?.filters?.formatDateTimeField(value, this.event.timezone) : ''
  }

  /*
   * v-clipboard methods
   */

  onCopySuccess (event) {
    Container.get(Notification).success((event.trigger.dataset.copyField || 'Information') + ' successfully copied.')
  }

  onCopyError (event) {
    Container.get(Notification).error('There was an error copying the ' + (event?.trigger.dataset.copyField ? event.trigger.dataset.copyField.toLocaleLowerCase() : 'information') + '. Please refresh and try again.')
  }

  /*
   * COMPUTED
   */

  /**
   * getter for payorder_number.
   * gets the last transaction and returns the number
   * or empty string.
   *
   * @type       {string}
   * @returns    {string}
   */
  get payorderNumber (): string {
    const transactions = this.registrationTransactions.data
    if (transactions && transactions.length > 0) {
      const lastTxn = transactions.slice(-1)[0]
      return lastTxn.payorder_number ? lastTxn.payorder_number : ''
    } else {
      return ''
    }
  }

  /**
   * getter for check_number.
   * gets the last transaction and returns the number
   * or empty string.
   *
   * @type       {string}
   * @returns    {string}
   */
  get checkNumber (): string {
    const transactions = this.registrationTransactions.data
    if (transactions && transactions.length > 0) {
      const lastTxn = transactions.slice(-1)[0]
      return lastTxn.check_number ? lastTxn.check_number : ''
    } else {
      return ''
    }
  }

  get transactions () {
    return this.registrationTransactions?.data || []
  }

  get badgePrintCount (): string {
    return this.$store.state.registration?.registration?.print_queue_count || ''
  }

  get currentRegistration () {
    const eventFields = this.participant_fields
    const {
      participant_group,
      participant_data,
      event_attendance_status
    } = this.$store.state.registration.registration
    const current_registration: Array<Record<string, any>> = []
    current_registration.push({
      value: this.payorderNumber ?? '',
      label: 'Payorder Number',
      field: 'payorder_number',
      on_form: false,
      option_group_uuid: 'no option group'
    }, {
      value: this.checkNumber ?? '',
      label: 'Check Number',
      field: 'check_number',
      on_form: false,
      option_group_uuid: 'no option group'
    }, {
      value: event_attendance_status ?? '',
      label: 'Event Attendance Status',
      field: 'event_attendance_status',
      on_form: false,
      option_group_uuid: 'no option group'
    })
    if (this.eventModules?.BADGES?.enabled) {
      current_registration.push({
        value: this.badgePrintCount,
        field: 'print_queue_count',
        label: 'Badge Print Count',
        on_form: false,
        option_group_uuid: 'no option group'
      })
    }

    for (const fieldIndex in eventFields) {
      const field = eventFields[fieldIndex].field
      const type = eventFields[fieldIndex].type
      const label = eventFields[fieldIndex].label
      const field_details = eventFields[fieldIndex].field_details
      const option_group_uuid = field_details != null ? field_details.option_group_uuid : 'no option group'
      if (type !== 'html' && type !== 'dropdown' && (option_group_uuid === 'no option group' || !option_group_uuid)) {
        let fieldValue: any
        if (field === 'group') {
          fieldValue = participant_group ? participant_group.group_id : ''
        } else {
          fieldValue = participant_data ? participant_data[field] : ''
        }
        current_registration.push({
          value: fieldValue,
          label: label,
          field: field,
          option_group_uuid: option_group_uuid,
          on_form: eventFields[fieldIndex].used
        })
      }
    }
    /*
     * Finally, sort alphabetically
     */
    current_registration.sort(function (a, b) {
      if (a.label < b.label) { return -1 }
      if (a.label > b.label) { return 1 }
      return 0
    })
    return current_registration
  }

  get fieldsOnForm () {
    const eventFields = this.participant_fields
    const fieldsOnForm: any = []
    for (const fieldIndex in eventFields) {
      if (eventFields[fieldIndex].used === 1) {
        fieldsOnForm.push(eventFields[fieldIndex].field)
      }
    }
    return fieldsOnForm
  }

  get fieldsOnFormOrCustom () {
    const eventFields = this.participant_fields
    const fieldsOnForm: any = []
    for (const fieldIndex in eventFields) {
      if (eventFields[fieldIndex].used === 1 || eventFields[fieldIndex].field.includes('custom_')) {
        fieldsOnForm.push(eventFields[fieldIndex].field)
      }
    }
    return fieldsOnForm
  }

  /**
   * returns the total number of pages for fields based off how many items are visible per page.
   */
  // get fieldsNumberOfPages (): number {
  //   return Math.ceil(this.$data.fields.items.length / this.$data.fields.itemsPerPage)
  // }

  /**
   * returns the total number of pages for advanced fields based off how many items are visible per page.
   */
  get advancedFieldsNumberOfPages (): number {
    return Math.ceil(this.$data.advanced_fields.items.length / this.$data.advanced_fields.itemsPerPage)
  }

  get isGroup (): boolean {
    return this.$store.state.registration.registration && this.$store.state.registration.registration.participant_group_uuid !== null
  }

  get idpEnabled () {
    return this.settings?.page_data?.idp_login_enabled?._default ?? false
  }

  get mcProEnabled () {
    return this.settings?.page_data?.mcpro_login_enabled?._default ?? false
  }

  get mcTradeEnabled () {
    return this.settings?.page_data?.mctrade_login_enabled?._default ?? false
  }

  /*
   * WATCHERS
   */

  @Watch('currentRegistration')
  onCurrentRegistration (payload: any) {
    if (payload) {
      const fields = this.customFields(payload, true)
      const unwantedFields = ['deleted_by', 'deletion_reason', 'password', 'password_reset_token']
      let advanced_fields = this.filterFields(payload, false)
        .filter(item => !unwantedFields.includes(item.field))
        .map((field: Record<string, any>) => {
          const readOnlyFields = ['cur_reg_fee', 'cur_group_reg_fee', 'payment_status', 'login_key', 'payment_total', 'uuid', 'barcodes', 'print_queue_count', 'badge_print_date', 'badge_print_status', 'updated_at', 'a2z_company_number', 'a2z_email', 'a2z_id', 'certificate_id', 'date_canceled', 'date_registered', 'date_transferred', 'import_id', 'import_uuid', 'can_export_certificates', 'cur_reg_fee_group', 'cur_reg_fee_lr', 'exported_certificate', 'group', 'highest_submitted_page', 'highest_submitted_page_lr', 'invoice_number', 'language', 'lr_payment_status', 'payment_total_lr', 'payment_vault_id', 'stripe_customer_id', 'payment_status_group', 'credits', 'alt_credits', 'has_started_filling_surveys', 'has_surveys_to_fill', 'transaction_cc_last4', 'a2z_company_association']
          field.readonly = readOnlyFields.includes(field.field)
          return field
        })

      // if surveys is not enabled, filter out the credits and alt_credits fields
      if (!this.eventModules?.SURVEYS.enabled) {
        const surveyFields = ['credits', 'alt_credits']
        advanced_fields = advanced_fields.filter(field => !surveyFields.includes(field.field))
      }

      // check settings if a2z is enabled. If not remove related fields.
      if (!this.idpEnabled) {
        const a2zFields = ['a2z_company_association', 'a2z_company_number', 'a2z_email', 'a2z_id']
        advanced_fields = advanced_fields.filter(item => !a2zFields.includes(item.field))
      }

      if (!this.mcProEnabled) {
        const mcproFields = ['mcpro_profile_id']
        advanced_fields = advanced_fields.filter(item => !mcproFields.includes(item.field))
      }

      if (!this.mcTradeEnabled) {
        const mcTradeFields = ['mctrade_profile_id']
        advanced_fields = advanced_fields.filter(item => !mcTradeFields.includes(item.field))
      }

      this.$data.fields.items = fields
      this.$data.fields.numberOfPages = Math.ceil(fields.length / this.$data.fields.itemsPerPage)
      this.$data.advanced_fields.items = advanced_fields
      this.$data.advanced_fields.numberOfPages = Math.ceil(advanced_fields.length / this.$data.advanced_fields.itemsPerPage)
    }
  }

  @Watch('advanced_search')
  onAdvancedSearch (payload: any) {
    if (payload) {
      this.$data.displayAdvanced = true
    } else {
      this.$data.displayAdvanced = false
    }
  }
}
