import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import BadgesMixin from '@/modules/level-two/views/event/modules/badges/mixins/badges.mixin'
import { mapState } from 'vuex'
import GtrActivityTab from './tab-components/activity/activity-tab.view.vue'
import GtrAuditLogTab from './tab-components/audit-log/audit-log-tab.view.vue'
import GtrBrowsingHistoryTab from './tab-components/browsing-history/browsing-history-tab.view.vue'
import GtrSessionsTab from './tab-components/track/sessions-tab.view.vue'
import GtrFeesTab from './tab-components/fees/fees-tab.view.vue'
import GtrFieldsTab from './tab-components/fields/fields-tab.view.vue'
import GtrGroupTab from './tab-components/group/group-tab.view.vue'
import GtrLeadsTab from './tab-components/leads/leads-tab.view.vue'
import GtrLineItemsTab from './tab-components/line-items/line-items-tab.view.vue'
import GtrActivitiesTab from './tab-components/activities/activities-tab.view.vue'
import GtrOptionGroupsTab from './tab-components/option-groups/option-groups-tab.view.vue'
import GtrTransactionsTab from './tab-components/transactions/transactions-tab.view.vue'
import GtrAddTransactionForm from './forms/add-transaction.form.vue'
import GtrBadgesTab from './tab-components/badges/badges-tab.view.vue'
import GtrSurveysTab from './tab-components/surveys/surveys.view.vue'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import '@/assets/styles/global.css'

@Component({
  name: 'GtrAttendeeEditView',
  computed: {
    ...mapState('registration', ['LRFees', 'registration', 'registrationAuditLog', 'registrationTransactions', 'registrationFees', 'participantsGroup', 'groupFees']),
    ...mapState('event', ['event', 'eventAllContent', 'eventModules']),
    ...mapState('security', ['currentUser']),
    ...mapState('payment', ['payment_processor_widget']),
    ...mapState('badges', ['badges', 'averyBadgesExport']),
    ...mapState('module', ['activatedEventModules']),
    ...mapState('sessions', ['activitiesByParticipant'])
  },
  components: {
    'activity-tab': GtrActivityTab,
    'fields-tab': GtrFieldsTab,
    'group-tab': GtrGroupTab,
    'transactions-tab': GtrTransactionsTab,
    'option-groups-tab': GtrOptionGroupsTab,
    'line-items-tab': GtrLineItemsTab,
    'activities-tab': GtrActivitiesTab,
    'fees-tab': GtrFeesTab,
    'audit-log-tab': GtrAuditLogTab,
    'browsing-history-tab': GtrBrowsingHistoryTab,
    'sessions-tab': GtrSessionsTab,
    'leads-tab': GtrLeadsTab,
    'badges-tab': GtrBadgesTab,
    'gtr-new-transaction-form': GtrAddTransactionForm,
    'surveys-tab': GtrSurveysTab
  }
})

export default class GtrAttendeeEditView extends mixins(GtrSuper, BadgesMixin) {
  event_uuid = ''

  attendee_uuid = ''

  event!: Record<string, any>

  registrationAuditLog!: Record<string, any>

  registration!: Record<string, any>

  eventAllContent!: Record<string, any>

  eventModules!: Record<string, any>

  currentUser!: Record<string, any>

  registrationTransactions!: Record<string, any>

  payment_processor_widget!: Record<string, any>

  registrationFees!: Record<string, any>

  participantsGroup!: Record<string, any>

  groupFees!: Record<string, any>

  deleteAttendee = false;

  badges!: Record<string, any>

  averyBadgesExport!: Record<string, any>

  activatedEventModules!: Record<string, any>

  activitiesByParticipant!: Array<any>

  data () {
    return {
      cancelAttendee: false,
      cancelAttendeeEmail: false,
      loading: false,
      submitting: false,
      attendee: '',
      reg_url: `${process.env.VUE_APP_REG_URL}`,
      forms: {
        showAddTransactionForm: false
      },
      tab: 0,
      current_tab: {
        description: '',
        value: -1,
        component: ''
      },
      tabs: [
        {
          description: 'Activity',
          value: 0,
          component: 'activity-tab'
        },
        {
          description: 'Fields',
          value: 1,
          component: 'fields-tab'
        },
        {
          description: 'Group',
          value: 2,
          component: 'group-tab'
        },
        {
          description: 'Transactions',
          value: 3,
          component: 'transactions-tab'
        },
        {
          description: 'Option groups',
          value: 4,
          component: 'option-groups-tab'
        },
        {
          description: 'Line items',
          value: 5,
          component: 'line-items-tab'
        },
        {
          description: 'Fees',
          value: 6,
          component: 'fees-tab'
        },
        {
          description: 'Audit Log',
          value: 7,
          component: 'audit-log-tab'
        },
        {
          description: 'Browsing History',
          value: 8,
          component: 'browsing-history-tab'
        },
        // {
        //   description: 'Sessions',
        //   value: 9,
        //   component: 'sessions-tab'
        // },
        {
          description: 'Leads',
          value: 10,
          component: 'leads-tab'
        },
        {
          description: 'Badges',
          value: 11,
          component: 'badges-tab'
        },
        {
          description: 'Surveys',
          value: 12,
          component: 'surveys-tab'
        },
        {
          description: 'Session Activities',
          value: 13,
          component: 'activities-tab'
        }
      ],
      profilePhotoModalOpen: false,
      profilePhotoFile: null,
      showBadgeExportModal: false,
      exportingAveryBadgePDF: false,
      selectedAveryTemplate: null,
      optionsOverCap: {},
      optionsOverCapMessage: ''
    }
  }

  /**
   * METHODS
   */
  created () {
    this.event_uuid = this.$route.params.event_uuid
    this.attendee_uuid = this.$route.params.attendee_uuid
    this.$store.commit('badges/SET_AVERY_BADGES_EXPORT_RESPONSE', {})
  }

  // revisit removing async and potential lazy load
  async mounted () {
    try {
      this.$data.loading = true
      this.$store.dispatch('common/showLoader', { value: true })
      await this.fetchRegistrationAuditLog()
      await this.fetchRegistration()
      await this.fetchEventAllContent()
      await this.fetchEventModules()
      await this.fetchCurrentUser()
      await this.fetchSurveys()

      if (this.visibleTabs.length > 0) {
        this.$data.current_tab = this.visibleTabs[0]
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
      this.$store.dispatch('common/hideLoader')
      this.handleBackgroundDataLoad()
    }
  }

  async handleBackgroundDataLoad () {
    this.fetchRegistrationTransactions()
    this.fetchAttendeeFees()
    this.fetchLRFees()
    this.fetchActivities()
    this.fetchPaymentProcessor()
    if (this.isGroup) {
      this.fetchParticipantsGroup()
      this.fetchGroupFees()
    }
    if (this.isBadgesEnabled) {
      this.getBadgeList()
    }
  }

  /*
   * Dispatch methods
   */
  private async fetchRegistrationAuditLog () {
    try {
      const payload = {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid,
        queryParam: ''
      }
      this.$store.dispatch('registration/getRegistrationAuditLog', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchRegistration () {
    try {
      const payload = {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid,
        queryParam: ''
      }
      await this.$store.dispatch('registration/getRegistration', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchEventAllContent () {
    try {
      await this.$store.dispatch('event/fetchEventModules', this.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  async makeGroup () {
    const groupData = await this.$store.dispatch('registration/createGroup', this.event_uuid)
    const payload = {
      event_uuid: this.$route.params.event_uuid,
      group_uuid: groupData.uuid,
      participant_uuid: this.attendee_uuid,
      data: {}
    }
    await this.$store.dispatch('registration/addParticipantToGroup', payload)
    window.location.reload()
  }

  private async fetchEventModules () {
    try {
      await this.$store.dispatch('event/getEventAllContent', this.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchActivities () {
    try {
      const payload = {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid
      }
      await this.$store.dispatch('sessions/fetchActivitiesForParticipant', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchCurrentUser () {
    try {
      await this.$store.dispatch('security/getCurrentUser')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchRegistrationTransactions () {
    try {
      const payload = {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid,
        queryParam: ''
      }
      await this.$store.dispatch('registration/getRegistrationTransactions', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchPaymentProcessor () {
    try {
      const payload = {
        event_uuid: this.event_uuid,
        form: 'registration'
      }
      await this.$store.dispatch('payment/getPaymentProcessor', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchAttendeeFees () {
    try {
      const payload = {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid,
        queryParam: ''
      }
      await this.$store.dispatch('registration/getRegistrationFees', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchLRFees () {
    try {
      const payload = {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid,
        queryParam: '?form=lr_orders'
      }
      await this.$store.dispatch('registration/getLRFees', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchParticipantsGroup () {
    try {
      const payload = {
        event_uuid: this.event_uuid,
        group_uuid: this.registration.participant_group_uuid
      }
      await this.$store.dispatch('registration/getParticipantsGroup', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchGroupFees () {
    try {
      const payload = {
        event_uuid: this.event_uuid,
        group_uuid: this.registration.participant_group_uuid
      }
      await this.$store.dispatch('registration/getGroupFees', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchSurveys (): Promise<void> {
    try {
      await this.$store.dispatch('surveys/fetchSurveys', {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid
      })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  async deselectOptionsOverCapacity () {
    const optionsOverCap = this.$data.optionsOverCap
    for (const optionGroupUuid in optionsOverCap) {
      for (const optionUuid in optionsOverCap[optionGroupUuid]) {
        await this.updateOption(optionGroupUuid, optionUuid, 0, false)
      }
    }
    this.$data.optionsOverCap = {}
    this.$data.optionsOverCapMessage = ''
    await this.fetchRegistration()
    await this.fetchEventAllContent()
  }

  /*
   * Cancel Participant
   */

  confirmCancel () {
    this.$data.cancelAttendee = true
  }

  showNextDialogForCancel ({ confirm }: any) {
    this.$data.cancelAttendeeEmail = confirm
  }

  async cancelParticipant (sendEmail: boolean): Promise<void> {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.event_uuid,
        registration_uuid: this.attendee_uuid,
        data: { status: 'Canceled', send_cancel_email: sendEmail }
      }
      await this.$store.dispatch('registration/editRegistration', payload)
      Container.get(Notification).success('Participant successfully canceled.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
      this.$data.cancelAttendee = false
    }
  }

  closeEmailModalAndSendEmail () {
    this.cancelParticipant(true)
    this.$data.cancelAttendee = false
    this.$data.cancelAttendeeEmail = false
  }

  closeEmailModalAndDoNotSendEmail () {
    this.cancelParticipant(false)
    this.$data.cancelAttendee = false
    this.$data.cancelAttendeeEmail = false
  }

  /*
   * Delete Participant
   */

  confirmDelete () {
    this.deleteAttendee = true
  }

  async deleteRegistration ({ confirm }: any): Promise<void> {
    try {
      if (confirm) {
        this.$data.loading = true
        const payload = {
          event_uuid: this.event_uuid,
          data: {
            company_uuid: this.$route.params.company_uuid,
            participant_uuid: this.attendee_uuid,
            deletion_reason: 'TODO'
          }
        }
        await this.$store.dispatch('registration/deleteRegistration', payload)
        Container.get(Notification).success('Participant successfully deleted.')
        this.$router.push({
          name: 'level-two.event.attendees',
          params: { event_uuid: this.event_uuid }
        })
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
      this.deleteAttendee = false
    }
  }

  /*
   * v-clipboard methods
   */

  onCopySuccess (event) {
    Container.get(Notification).success((event.trigger.dataset.copyField || 'Information') + ' successfully copied.')
  }

  onCopyError (event) {
    Container.get(Notification).error('There was an error copying the ' + (event?.trigger.dataset.copyField ? event.trigger.dataset.copyField.toLocaleLowerCase() : 'information') + '. Please refresh and try again.')
  }

  /*
   * Transaction methods
   */
  handleShowAddTransactionForm () {
    this.$data.forms.showAddTransactionForm = true
  }

  async handleCloseAddTransactionForm () {
    this.$data.forms.showAddTransactionForm = false
    await this.fetchRegistrationTransactions()
  }

  @Watch('registration', { immediate: true })
  onRegistrationChange (payload: any) {
    if (payload) {
      this.$data.attendee = payload.participant_data
      if (this.$data.attendee && this.$data.attendee.barcodes?.[0] === '') {
        Container.get(Notification).error('This record has no barcode.')
      }
    }
  }

  @Watch('registration', { immediate: true })
  @Watch('eventAllContent', { immediate: true })
  checkForOptionsOverCap () {
    if (this.eventAllContent?.settings?.cap_complete_only && this.registration?.participant_data?.status !== 'Complete') {
      // Check for selected options over cap
      const selectedOptions = this.registration.selected_option_qty
      const optionGroups = this.eventAllContent.option_groups
      const optionsOverCap: { [index: string]: { [index: string]: number } } = {}
      let message = ''

      for (const optionGroupUuid in selectedOptions) {
        for (const optionUuid in selectedOptions[optionGroupUuid]) {
          const optionGroup = optionGroups.find(group => group.uuid === optionGroupUuid)
          if (optionGroup) {
            const option = optionGroup?.options.find(opt => opt.uuid === optionUuid)
            if (option && Number.isInteger(option.cap) && option.count_complete >= option.cap) {
              if (!optionsOverCap[optionGroupUuid]) {
                optionsOverCap[optionGroupUuid] = {}
              }
              optionsOverCap[optionGroupUuid][optionUuid] = selectedOptions[optionGroupUuid][optionUuid]
              message += `<li>${optionGroup.name}: ${option.name}</li>`
            }
          }
        }
      }

      this.$data.optionsOverCap = optionsOverCap
      this.$data.optionsOverCapMessage = message
    }
  }

  async handleAction () {
    const payload = {
      event_uuid: this.event_uuid,
      group_uuid: this.registration.participant_group_uuid
    }
    await this.$store.dispatch('registration/getGroupFees', payload)
  }

  /**
   * COMPUTED
   */
  get fromA2Z (): boolean {
    return !!this.registration.participant_data.a2z_id
  }

  get hasTransactions (): boolean {
    return (this.registrationTransactions.data?.length > 0)
  }

  get event_identifier (): string {
    return this.eventAllContent?.event?.event_identifier || ''
  }

  get login_key (): string {
    return this.registration?.participant_data?.login_key || ''
  }

  get qrcode_barcode (): string {
    return this.registration?.barcode_links?.qrcode || ''
  }

  get loginLink (): string {
    return `${this.$data.reg_url}/${this.event_identifier}/register/loginregistration/${this.login_key}/0`
  }

  get attendeeRegType (): string {
    if (this.registration.registration_type && this.eventAllContent?.option_groups) {
      const regTypeUuid = this.registration.registration_type
      const optionGroups = this.eventAllContent.option_groups
      const regTypeOptionGroup = optionGroups.filter(group => group.name === 'Registration Types').pop()
      let regTypeResult = ''
      if (regTypeOptionGroup.options.length) {
        const foundRegType = regTypeOptionGroup.options.filter(option => option.uuid === regTypeUuid).pop()
        if (foundRegType === undefined) {
          return ''
        } else {
          regTypeResult = foundRegType.name
          return regTypeResult
        }
      }
    }
    return ''
  }

  get attendeeLastActivity () {
    const auditLog = this.registrationAuditLog.data
    if (Array.isArray(auditLog)) {
      const lastEntry = auditLog.pop()
      return lastEntry.updated_at
    }
    return ''
  }

  get attendeeSummary () {
    const summary = [
      {
        label: 'Status',
        value: this.$data.attendee.status ? this.$data.attendee.status : 'Unknown',
        showCopyButton: false
      },
      {
        label: 'Registration Type',
        value: this.attendeeRegType || 'Default',
        showCopyButton: false
      },
      {
        label: 'Registration Date',
        value: this.$data.attendee.date_registered ? this.$options?.filters?.formatReportDateTimeLocal12Hr(this.$data.attendee.date_registered, this.event.timezone) : 'Registration Incomplete',
        showCopyButton: false
      },
      {
        label: 'Email',
        value: this.$data.attendee.email ? this.$data.attendee.email : 'Unknown',
        showCopyButton: true
      },
      {
        label: 'Last Activity',
        value: this.attendeeLastActivity ? this.$options?.filters?.formatReportDateTimeLocal12Hr(this.attendeeLastActivity, this.event.timezone) : 'No Activity',
        showCopyButton: false
      },
      {
        label: 'Barcode',
        value: this.$data.attendee.barcodes ? this.$data.attendee.barcodes[0] : 'Unknown',
        showCopyButton: true
      }
    ]
    if (this.isLeadsEnabled) {
      const lrStatus = {
        label: 'LR Status',
        value: this.$data.attendee.lr_status ? this.$data.attendee.lr_status : 'N/A',
        showCopyButton: false
      }
      summary.splice(0, 0, lrStatus)
    }
    if (this.$data.attendee.company) {
      const company = {
        label: 'Company',
        value: this.$data.attendee.company,
        showCopyButton: false
      }
      summary.splice(4, 0, company)
    }
    return summary
  }

  get isGroup (): boolean {
    return this.registration && this.registration.participant_group_uuid !== null
  }

  get isLeadsEnabled (): boolean {
    return this.eventModules?.LEADS?.enabled ?? false
  }

  get isTrackEnabled (): boolean {
    return this.eventModules?.SESSION_TRACKING?.enabled ?? false
  }

  get isBadgesEnabled (): boolean {
    return this.eventModules?.BADGES?.enabled ?? false
  }

  get isSurveysEnabled (): boolean {
    return this.eventModules?.SURVEYS?.enabled ?? false
  }

  get isCurrentUserSuperAdmin (): boolean {
    if (this.currentUser) {
      return this.currentUser.access_level === 'SUPER_ADMIN'
    }
    return false
  }

  get isCurrentUserCompanyAdmin (): boolean {
    if (this.currentUser) {
      return this.currentUser.access_level === 'COMPANY_ADMIN'
    }
    return false
  }

  get surveyLoginLink (): string {
    return `${this.$data.reg_url}/${this.event_identifier}/surveys/loginsurveys/${this.login_key}/0`
  }

  /**
   * returns the view permission of the corresponding tab on edit participant view.
   * @param tab
   * @returns boolean
   */
  private tabPermissed (tab: any) {
    switch (tab.description) {
      case 'Activity':
      case 'Audit Log':
        return Vue.prototype.$hasPermission('participants.activity_tab.view')
      case 'Fields':
        return Vue.prototype.$hasPermission('participants.fields.view')
      case 'Transactions':
        return Vue.prototype.$hasPermission('participants.transactions.view')
      case 'Option groups':
        return Vue.prototype.$hasPermission('participants.option_groups.view')
      case 'Line items':
        return Vue.prototype.$hasPermission('participants.line_items.view')
      case 'Group':
        return Vue.prototype.$hasPermission('participants.group.view')
      case 'Fees':
        return Vue.prototype.$hasPermission('participants.fees.view')
      case 'Leads':
        return Vue.prototype.$hasPermission('participants.leads.view')
      // This permission does not exist yet
      // case 'Badges':
      //   return Vue.prototype.$hasPermission('participants.badges.view')
      default:
        return true
    }
  }

  get visibleTabs () {
    const tabItems = this.$data.tabs.filter(tab => {
      if ((!this.isGroup && tab.description === 'Group') || !this.tabPermissed(tab)) {
        return false
      } else if (tab.description === 'Audit Log' || tab.description === 'Browsing History') {
        if (!this.isCurrentUserSuperAdmin && !this.tabPermissed(tab)) {
          return false
        }
      } else if (tab.description === 'Leads') {
        if (!this.isLeadsEnabled || (!this.tabPermissed(tab) && !this.isCurrentUserSuperAdmin && !this.isCurrentUserCompanyAdmin)) {
          return false
        }
      } else if (tab.description === 'Surveys') {
        if (!this.isSurveysEnabled || !this.tabPermissed(tab)) {
          return false
        }
      } else if (tab.description === 'Session Activities') {
        if (!this.isTrackEnabled || this.activitiesByParticipant.length === 0) {
          return false
        }
      } else if (tab.description === 'Sessions') {
        if (!this.isTrackEnabled) {
          return false
        }
      } else if (tab.description === 'Badges') {
        if (!this.isBadgesEnabled || !this.isDefaultBadge || !this.tabPermissed(tab)) {
          return false
        }
      } else if (!this.tabPermissed(tab)) {
        return false
      }
      return true
    })
    return tabItems
  }

  get currentTabComponent () {
    return this.$data.current_tab.component
  }

  handleProfilePhotoModalOpen () {
    this.$data.profilePhotoModalOpen = true
  }

  handleProfilePhotoModalClose () {
    this.$data.profilePhotoModalOpen = false
  }

  onProfilePhotoChange (payload: File) {
    if (payload) {
      this.$data.profilePhotoFile = payload
    }
  }

  async submitProfilePhoto () {
    const form = (this.$refs.updateProfilePhotoForm as HTMLFormElement)
    if (form.validate()) {
      try {
        this.$data.submitting = true
        const response = await this.$store.dispatch('media/uploadMedia', { event_uuid: this.$route.params.event_uuid, fileData: this.$data.profilePhotoFile, fetch: false, participant_uuid: this.attendee_uuid })
        const payload = {
          event_uuid: this.event_uuid,
          registration_uuid: this.attendee_uuid,
          data: { profile_photo: response.responseEventMedia.data.url },
          send_email_override: false
        }
        await this.$store.dispatch('registration/editRegistration', payload)
        Container.get(Notification).success('Participant successfully edited.')
        this.handleProfilePhotoModalClose()
        await this.fetchRegistration()
        form.reset()
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }
  }

  async getBadgeList () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('badges/fetchBadges', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  get activeBadges () {
    if (this.badges) {
      return this.badges.filter(badge => badge.active)
    }
  }

  get isDefaultBadge () {
    if (this.activeBadges.length) {
      for (let i = 0; i < this.activeBadges.length; i++) {
        const badge = this.activeBadges[i]
        if (badge.registration_type === '_default' || badge.registration_type_text === '_default') {
          return true
        }
      }
    }
    return false
  }

  get activeBadgeSize () {
    if (this.activeBadges) {
      for (let i = 0; i < this.activeBadges.length; i++) {
        const badge = this.activeBadges[i]
        if (badge.registration_type === '_default' || badge.registration_type_text === '_default') {
          return badge.badge_properties.size
        }
      }
      if (this.registration.registration_type) {
        for (let i = 0; i < this.activeBadges.length; i++) {
          const badge = this.activeBadges[i]
          if (badge.registration_type === this.registration.registration_type) {
            return badge.badge_properties.size
          }
        }
      }
    }
    return ''
  }

  get isAverySupported () {
    if (this.activeBadgeSize) {
      return this.supportedAverySizes.includes(this.activeBadgeSize)
    }
    return false
  }

  /**
   * This could change, but we don't send selectedAveryTemplate to BE.
   * Instead, we send 1 Avery template based off of badge template size.
   */
  get averyTemplateType () {
    if (this.activeBadgeSize) {
      if (this.activeBadgeSize === '3-3/8" x 2-1/3"') {
        return '5395'
      } else if (this.activeBadgeSize === '3-1/2" x 2-1/4"') {
        return '5390'
      } else if (this.activeBadgeSize === '4" x 2"') {
        return '5163'
      } else if (this.activeBadgeSize === '4" x 3"') {
        return '5384'
      }
    } else {
      return ''
    }
  }

  get compatibleAveryTemplates () {
    if (this.activeBadgeSize) {
      const compatibleTemplates: any = []
      for (let i = 0; i < this.averyTemplates.length; i++) { // averyTemplates from badges.mixin.ts
        const template = this.averyTemplates[i]
        if (template.size === this.activeBadgeSize) {
          compatibleTemplates.push(template.number)
        }
      }
      return compatibleTemplates
    }
    return []
  }

  handleOpenBadgeExportModal () {
    this.$data.showBadgeExportModal = true
  }

  handleCloseBadgeExportModal () {
    this.$data.showBadgeExportModal = false
    this.$data.selectedAveryTemplate = null
    setTimeout(() => {
      this.$data.exportingAveryBadgePDF = false
    }, 1000)
  }

  async handleExportBadgePDF () {
    try {
      this.$data.exportingAveryBadgePDF = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        data: {
          type: this.averyTemplateType,
          participant_uuids: [this.attendee_uuid]
        }
      }
      await this.$store.dispatch('badges/createAveryBadgesExport', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  async downloadSurveysResponses () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        participant_uuid: this.$data.attendee_uuid
      }
      const response = await this.$store.dispatch('registration/downloadSurveysResponses', payload)
      window.open(response.data, '_blank')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async exportParticipantReport (report: string) {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        participant_uuid: this.$data.attendee_uuid
      }
      const response = await this.$store.dispatch(report, payload)
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async downloadInvoice () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        participant_uuid: this.$data.attendee_uuid
      }
      const response = await this.$store.dispatch('registration/downloadInvoice', payload)
      window.open(response.data.invoice_pdf_url, '_blank')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  @Watch('averyBadgesExport', { immediate: true })
  onAveryBadgesExportChange (payload) {
    if (payload) {
      this.handleCloseBadgeExportModal()
    }
  }

  async updateOption (option_group_uuid, option_uuid, quantity, removeOtherSelections) {
    try {
      this.$data.loading = true
      await this.$store.dispatch('registration/updateOptionQty', {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid,
        option_group_uuid,
        option_uuid,
        qty: quantity,
        removeOtherSelections
      })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }
}
