import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Component, Ref, Prop, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { ActionType } from '@/modules/common/enums/action-types.enum'

@Component({
  name: 'GtrAddTransactionForm',
  computed: {
    ...mapState('payment', [
      'stripe_token',
      'stripe_checkout_token',
      'payment_processor', 'payment_processor_lr', 'payment_properties', 'payment_processor_widget', 'payment_settings'
    ]),
    ...mapState('registration', ['registration', 'LRFees', 'registrationFees', 'groupFees', 'registrationTransactions']),
    ...mapState('event', ['event', 'eventAllContent'])
  },
  components: {
    StripeCard: () => import('@/modules/common/components/stripe/stripe-card/stripe-card.vue'),
    StripeCheckout: () => import('@/modules/common/components/stripe/stripe-checkout/stripe-checkout.vue'),
    AuthnetForm: () => import('@/modules/common/components/authnet/authnet-form/authnet-form.vue')
  }
})
export default class GtrAddTransactionForm extends GtrSuper {
  @Prop({ required: true, type: Boolean, default: false })
  visible: boolean | undefined

  @Ref()
  readonly observerAddTransactionForm!: InstanceType<typeof ValidationObserver>;

  addTransactionTableHeaders = [
    {
      text: 'Line Item',
      value: 'name'
    },
    {
      text: 'Amount',
      value: 'qty'
    },
    {
      text: 'Price',
      value: 'price'
    },
    {
      text: 'Add Transaction',
      value: 'addTransaction'
    }
  ];

  transactionTypes = [
    {
      label: 'Cash',
      value: 'cash'
    },
    {
      label: 'Check',
      value: 'check'
    },
    {
      label: 'ACH',
      value: 'ach'
    },
    {
      label: 'PO',
      value: 'payorder'
    },
    {
      label: 'Credit Card',
      value: 'charge'
    },
    {
      label: 'Offline',
      value: 'offline'
    }
  ]

  registrationFees!: Record<string, any>;

  groupFees!: Record<string, any>;

  LRFees!: Record<string, any>;

  registrationTransactions!: Record<string, any>;

  registration!: Record<string, any>;

  itemsToAdd = [];

  event!: Record<string, any>;

  payment_type = '';

  lrorders_transaction = 0;

  stripeLoading = false;

  stripeError = '';

  submitting = false;

  payment_properties!: Record<string, any>;

  payment_processor!: Array<string>;

  payorder_number = '';

  check_number = '';

  note = '';

  lrOrdersTransaction = [{ text: 'Registration', value: 0 }, { text: 'Lead Retrieval Order', value: 1 }]
  states = [
    'AL',
    'AK',
    'AS',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FM',
    'FL',
    'GA',
    'GU',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MH',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'MP',
    'OH',
    'OK',
    'OR',
    'PW',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VI',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY'
  ]

  provinces = [
    'AB',
    'BC',
    'MB',
    'NB',
    'NL',
    'NS',
    'ON',
    'PE',
    'QC',
    'SK',
    'NT',
    'NU',
    'YT'
  ]

  countries = [
    'United States',
    'Afghanistan',
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Antigua & Deps',
    'Argentina',
    'Armenia',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bhutan',
    'Bolivia',
    'Bosnia Herzegovina',
    'Botswana',
    'Brazil',
    'Brunei',
    'Bulgaria',
    'Burkina',
    'Burundi',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cape Verde',
    'Central African Rep',
    'Chad',
    'Chile',
    'China',
    'Colombia',
    'Comoros',
    'Congo',
    'Congo {Democratic Rep}',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'East Timor',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Ethiopia',
    'Fiji',
    'Finland',
    'France',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Greece',
    'Grenada',
    'Guatemala',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Honduras',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland {Republic}',
    'Israel',
    'Italy',
    'Ivory Coast',
    'Jamaica',
    'Japan',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Korea North',
    'Korea South',
    'Kosovo',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macedonia',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Mauritania',
    'Mauritius',
    'Mexico',
    'Micronesia',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Morocco',
    'Mozambique',
    'Myanmar, {Burma}',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Qatar',
    'Romania',
    'Russian Federation',
    'Rwanda',
    'St Kitts & Nevis',
    'St Lucia',
    'Saint Vincent & the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome & Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'Swaziland',
    'Sweden',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'Togo',
    'Tonga',
    'Trinidad & Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Vatican City',
    'Venezuela',
    'Vietnam',
    'Yemen',
    'Zambia',
    'Zimbabwe'
  ]

  data () {
    return {
      showStripeCheckout: false,
      stripe_checkout_token_data: null,
      loading: false,
      event_all_content: null,
      payment_processor_currency: null,
      table: {
        loading: false,
        headers: [
          {
            text: 'Date',
            align: 'start',
            sortable: false,
            value: 'created_at'
          },
          {
            text: 'Transaction ID',
            align: 'start',
            sortable: false,
            value: 'external_id'
          },
          {
            text: 'Last 4',
            align: 'start',
            sortable: false,
            value: 'last_four'
          },
          {
            text: 'Expiration',
            align: 'start',
            sortable: false,
            value: 'expiration'
          },
          {
            text: '',
            value: 'actions',
            searchable: false,
            sortable: false,
            width: '90px'
          }
        ]
      },
      vaultTransactions: [],
      submitting: false,
      showForm: false,
      formWidth: 800,
      addTransactionForm: {
        first_name: null,
        last_name: null
      },
      transactionDialog: false,
      amount: 0,
      card: false,
      stripe: false,
      elements: '',
      current_capture_transaction: false,
      credit_card_number: '',
      credit_cvv: '',
      credit_first_name: '',
      credit_last_name: '',
      credit_address: '',
      credit_city: '',
      credit_state: '',
      credit_province: '',
      credit_zip: '',
      credit_country: '',
      credit_expiration_month: '',
      credit_expiration_year: '',
      expirationMonthsItems: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      _registration: null,
      auth: '',
      stripeAPIToken: 'pk_test_XXX',
      line_selected: [],
      paymentProcessorConfigured: false,
      payment_processor_widget_uuid: null,
      _event: {},
      showGroupFees: false,
      tableHeaders: [{ text: 'Item', value: 'name' }, { text: 'Qty', value: 'qty' }, { text: 'Total', value: 'total' }],
      tableHeadersGroup: [{ text: 'Participant Name', value: 'participant_name' }, { text: 'Item', value: 'name' }, { text: 'Qty', value: 'qty' }, { text: 'Total', value: 'total' }],
      makeStatusComplete: false,
      sendInvoiceEmail: false
    }
  }

  async onTransactionTypeChange () {
    await this.fetchPaymentProcessor()
  }

  async onPaymentTypeValueChange (paymentType: string) {
    if (!['charge', 'vault'].includes(paymentType)) {
      return
    }
    await this.fetchPaymentProcessor()

    if ((this.paymentProcessorUsed === 'stripe' || this.paymentProcessorUsed === 'authnet') && this.$data.paymentProcessorConfigured) {
      this.processVaultRegistrationTransactions()
    }
  }

  get hideAddTransactionButton () {
    const paymentType = this.$data.payment_type
    if (paymentType === 'charge' && this.paymentProcessorUsed === 'stripecheckout') {
      return true
    }
    return false
  }

  /* @Watch('payment_processor_widget_id')
  onPaymentProcessorWidgetIdValueChange (newValue: any) {
    if (this.payment_processor_widget_id) {
      const payment_settings_data = {
        widget: newValue,
        event_uuid: this.$data._event.uuid
      }
      this.$store.dispatch('payment/getPaymentSettings', payment_settings_data)
    }
    if (newValue) {
      const payload = {
        event_identifier: this.event_identifier,
        participant_uuid: this.$data._registration.uuid,
        widget: newValue
      }
      this.$store.dispatch('payment/getPaymentProperties', payload)
    }
  } */

  @Watch('eventAllContent', { immediate: true })
  onEventAllContentChange (value: any) {
    if (value) {
      this.$data.event_all_content = value
      this.$data.payment_processor_currency = this.$data.event_all_content.payment_processor_currency
    }
  }

  @Watch('stripe_checkout_token', { immediate: true })
  onStripeCheckoutTokenChange (value: any) {
    if (value !== null && value !== undefined && value.tokenData !== null && value.tokenData !== undefined) {
      this.$data.stripe_checkout_token_data = value
    }
  }

  @Watch('payment_processor_widget')
  async onPaymentProcessorWidgetChange (payload: any) {
    if (payload.length && (!this.$data.payment_processor_widget_uuid || this.$data.payment_processor_widget_uuid === null)) {
      this.$data.payment_processor_widget_uuid = payload.shift()
    }
  }

  @Watch('payment_processor')
  onPaymentProcessorChange (payload: any) {
    if (payload.length) {
      // assign processor to component state.
      let processorScript: any
      if (!document.querySelector('#processor_script')) {
        processorScript = document.createElement('script')
        processorScript.setAttribute('id', 'processor_script')
        processorScript.async = true
        if (this.paymentProcessorUsed === 'authnet') {
          processorScript.dataset.processor = 'authnet'
          document.head.appendChild(processorScript)
        } else if (this.paymentProcessorUsed === 'stripe') {
          processorScript.dataset.processor = 'stripe'
          document.head.appendChild(processorScript)
        }
      }
    }
  }

  @Watch('payment_properties')
  onPaymentPropertiesValueChange () {
    let processorScript: any
    if (this.paymentProcessorUsed === 'authnet') {
      // this.$data.auth = (window as any).Accept
      // eslint-disable-next-line
      processorScript = document.querySelector('#processor_script')!
      processorScript.setAttribute('src', this.payment_properties.endpoint)
    }
  }

  @Watch('payment_settings')
  async onPaymentSettingsValueChange (payload: any) {
    if (payload && !Array.isArray(payload.definedProperties)) {
      this.$data.paymentProcessorConfigured = true
      await this.fetchPaymentProperties()
    }
  }

  @Watch('visible', { immediate: true })
  onVisibleValueChange (newVisibleValue: boolean) {
    this.$data.showForm = newVisibleValue
  }

  async mounted () {
    await this.fetchEvent()
    this.$data._event = this.$store.state.event.event
    await this.fetchPaymentProcessor()
    if (this.paymentProcessorUsed === 'stripe') {
      await this.fetchStripeToken()
    }
  }

  get stripeKey () {
    return this?.payment_properties?.tokens?.stripe_key || ''
  }

  private async fetchEvent () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('event/fetchEvent', this.$route.params.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async loadStripeCheckout () {
    await this.fetchStripeCheckoutToken()
    this.$data.showStripeCheckout = true
  }

  closeStripeCheckout () {
    this.$data.showStripeCheckout = false
  }

  handleTransactionAttempt () {
    this.$data.loading = false
    this.$data.submitting = false
    this.cleanFormModel()
    this.onClose()
    this.fetchRegistration()
    this.fetchRegistrationAuditLog()
    this.$store.dispatch('registration/getRegistrationLeadsAppCodes', {
      event_uuid: this.$data._event.uuid,
      participant_uuid: this.$route.params.attendee_uuid,
      queryParam: ''
    })
  }

  private async fetchPaymentSettings () {
    try {
      const payment_settings_data = {
        widget: this.$data.payment_processor_widget_uuid,
        event_uuid: this.$data._event.uuid,
        form: this.$data.lrorders_transaction ? 'lr_orders' : 'registration'
      }
      await this.$store.dispatch('payment/getPaymentSettings', payment_settings_data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private viewGroupLineItems () {
    this.$data.itemsToAdd = []
    this.$data.showGroupFees = !this.$data.showGroupFees
  }

  get lineItems () {
    if (this.$data.showGroupFees) {
      for (const lineItemKey in this?.groupFees?.line_items) {
        const lineItem = this?.groupFees?.line_items[lineItemKey]
        lineItem.participant_name = this?.groupFees?.group_participant_names[lineItem.participant_uuid]
        lineItem.key = lineItemKey
        this.groupFees.line_items[lineItemKey] = lineItem
      }
      return this?.groupFees?.line_items || []
    }
    for (const lineItemKey in this?.registrationFees?.line_items) {
      const lineItem = this?.registrationFees?.line_items[lineItemKey]
      lineItem.key = lineItemKey
      this.registrationFees.line_items[lineItemKey] = lineItem
    }
    return this?.registrationFees?.line_items || []
  }

  get groupFeesAll () {
    return this?.groupFees
  }

  get isParent () {
    return this.registration?.participant_group?.parent
  }

  get lineItemsLR () {
    return this?.LRFees?.line_items || []
  }

  get itemsSelected () {
    return (this?.itemsToAdd || []).length > 0
  }

  get transactionTableHeaders () {
    return this.addTransactionTableHeaders
  }

  get showAuthnetFormFields () {
    if (this.paymentProcessorUsed === 'authnet' && (this.$data.payment_type === 'charge' || this.$data.payment_type === 'vault')) {
      return true
    }
  }

  get showAuthnetStripeFormFields () {
    if ((this.paymentProcessorUsed === 'authnet' || this.paymentProcessorUsed === 'stripe') && (this.$data.payment_type === 'charge' || this.$data.payment_type === 'vault')) {
      return true
    }
  }

  get processorEndpoint () {
    // eslint-disable-next-line
    return this.payment_properties!.endpoint
  }

  get paymentProcessorUsed () {
    // eslint-disable-next-line
    return this.payment_processor[0]!
  }

  // get transactionTypes () {
  //   return this.$data._transaction_types.filter(transaction_type => {
  //     if ((transaction_type.value === 'charge' || transaction_type.value === 'vault' || transaction_type.value === 'capture') && (!this.paymentProcessorUsed || !this.$data.paymentProcessorConfigured)) {
  //       return false
  //     }
  //     return true
  //   })
  // }

  get totalAmount () {
    let total = 0
    if (this.$data.line_selected) {
      for (const itemIndex in this.$data.line_selected) {
        const currentItem = this.$data.line_selected[itemIndex]
        if (currentItem) {
          total += currentItem.total
        }
      }
    }
    return total.toFixed(2)
  }

  processVaultRegistrationTransactions () {
    this.$data.vaultTransactions = []
    this.$store.state.registration.registrationTransactions.data.forEach(row => {
      if (row.transaction_type === 'Vault') {
        this.$data.vaultTransactions.push({
          uuid: row.uuid,
          created_at: row.created_at,
          external_id: row.external_id,
          transaction_type: row.transaction_type,
          amount: row.amount,
          line_items: row.line_items,
          last4: row.last4,
          expiration: row.expiration,
          result: row.result,
          response_text: row.response_text,
          success: row.success,
          voided: row.voided
        })
      }
    })
  }

  async fetchPaymentProcessor () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$data._event.uuid,
        form: this.$data.lrorders_transaction ? 'lr_orders' : 'registration'
      }
      const response = await this.$store.dispatch('payment/getPaymentProcessor', payload)
      if (Object.keys(response?.widgets || {}).length && (this.$data.payment_processor_widget_uuid === null || this.paymentProcessorUsed === null)) {
        const widget = Object.keys(response.widgets)
        this.$data.payment_processor_widget_uuid = widget.shift()
      }
      if (this.$data.payment_processor_widget_uuid !== null) {
        await this.fetchPaymentProperties()
        await this.fetchPaymentSettings()
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchPaymentProperties () {
    try {
      this.$data.loading = true
      const payload = {
        event_identifier: this.event_identifier,
        participant_uuid: this.registration.uuid,
        widget: this.$data.payment_processor_widget_uuid,
        form: this.$data.lrorders_transaction ? 'lr_orders' : 'registration'
      }
      await this.$store.dispatch('payment/getPaymentProperties', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchStripeCheckoutToken () {
    try {
      let url = window.location.href
      if (url.indexOf('?')) {
        url = url.split('?')[0]
      }
      this.$data.loading = true
      const payload = {
        event_identifier: this.event_identifier,
        participant_uuid: this.registration.uuid,
        widget: this.$data.payment_processor_widget_uuid,
        form: this.$data.lrorders_transaction ? 'lr_orders' : 'registration',
        payment_details: { return_url: url + '?stripe_session_id={CHECKOUT_SESSION_ID}&widget=' + this.$data.payment_processor_widget_uuid },
        line_items: this.$data.itemsToAdd
      }
      await this.$store.dispatch('payment/getStripeCheckoutToken', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchStripeToken () {
    try {
      this.$data.loading = true
      const payload = {
        event_identifier: this.event_identifier,
        participant_uuid: this.registration.uuid,
        widget: this.$data.payment_processor_widget_uuid,
        form: this.$data.lrorders_transaction ? 'lr_orders' : 'registration'
      }
      await this.$store.dispatch('payment/getStripeToken', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  get currencySymbol (): string {
    return this.$store.state.registration.registrationFees.currency
  }

  get event_identifier () {
    return this.$data._event.event_identifier
  }

  get stripe_intent (): any {
    if (
      this.$store.state.payment.stripe_token !== null &&
      this.$store.state.payment.stripe_token.tokenData !== null &&
      this.$store.state.payment.stripe_token.tokenData.stripeIntentSecret
    ) {
      return this.$store.state.payment.stripe_token.tokenData.stripeIntentSecret
    } else {
      return null
    }
  }

  setCurrentCaptureTransaction (transaction) {
    this.$data.current_capture_transaction = transaction
  }

  async addTransaction () {
    try {
      this.$data.loading = true
      this.$data.submitting = true
      if (['cash', 'check', 'payorder', 'ach', 'offline'].includes(this.$data.payment_type)) {
        const payload = {
          event_uuid: this.event.uuid,
          participant_uuid: this.registration.uuid,
          data: {
            line_items: this.itemsToAdd,
            payment_type: this.$data.payment_type,
            payorder_number: this.payorder_number,
            check_number: this.check_number,
            form: this.$data.lrorders_transaction ? 'lr_orders' : 'registration',
            make_complete: this.$data.lrorders_transaction ? 1 : 0,
            notes: this.note ?? ''
          }
        }

        // if the type is not payorder (payorder) remove the payorder_number.
        if (this.$data.payment_type !== 'payorder') delete payload.data.payorder_number
        // if the type is not check remove the check_number.
        if (this.$data.payment_type !== 'check') delete payload.data.check_number
        // if the type is not offline remove the note.
        if (this.$data.payment_type !== 'offline') delete payload.data.notes

        await this.$store.dispatch('payment/createNonWidgetTransaction', payload)
      } else {
        const data = {
          event_uuid: this.$data._event.uuid,
          participant_uuid: this.registration.uuid,
          first_name: this.$data.credit_first_name,
          last_name: this.$data.credit_last_name,
          address: this.$data.credit_address,
          city: this.$data.credit_city,
          state: this.$data.credit_state,
          zip: this.$data.credit_zip,
          country: this.$data.credit_country,
          credit_card_number: this.$data.credit_card_number,
          credit_cvv: this.$data.credit_cvv,
          credit_expiration_month: this.$data.credit_expiration_month,
          credit_expiration_year: this.$data.credit_expiration_year,
          api_login_id: '',
          public_client_key: '',
          widget_id: '',
          payment_type: '',
          transaction_id: ''
        }
        const payment_settings = this.$store.state.payment.payment_settings
        if (this.paymentProcessorUsed === 'stripe') {
          data.api_login_id = payment_settings.definedProperties.api_login_id
          data.public_client_key = payment_settings.definedProperties.public_client_key
          data.widget_id = this.$data.payment_processor_widget_uuid
          data.payment_type = this.$data.payment_type
          data.transaction_id = this.$data.current_capture_transaction?.uuid || ''
          if (this.$data.payment_type === 'capture') {
            this.$store.dispatch('payment/createStripeTransaction', data)
          }

          if (this.$data.payment_type === 'charge') {
            const response = await this.$data.stripe.createToken(this.$data.card)

            this.$data.card.clear()

            const cardToken = response.token.id

            const tokenizedData: any = {
              line_items: this.itemsToAdd,
              payment_details: {
                event_uuid: this.$data._event.uuid,
                participant_uuid: this.$route.params.attendee_uuid,
                first_name: this.$data.credit_first_name,
                last_name: this.$data.credit_last_name,
                address: this.$data.credit_address,
                city: this.$data.credit_city,
                zip: this.$data.credit_zip,
                country: this.$data.credit_country,
                stripeToken: cardToken,
                page_number: '',
                currentLanguage: this.$data.currentLanguage,
                event_identifier: this.event_identifier,
                widget_id: this.$data.payment_processor_widget_uuid,
                transaction_type: this.$data.payment_type,
                payment_type: this.$data.payment_type
              }
            }
            if (this.$data.credit_country === 'United States') {
              tokenizedData.payment_details.state = this.$data.credit_state
            } else if (this.$data.credit_country === 'Canada') {
              tokenizedData.payment_details.province = this.$data.credit_province
            }
            await this.submitPaymentData(tokenizedData)
            this.$emit('action', { type: ActionType.SUCCESS, message: 'Transaction successfully added.' })
            Container.get(Notification).success('Transaction successfully added.')
          }

          if (this.$data.payment_type === 'vault') {
            const response = await this.$data.stripe.confirmCardSetup(this.stripe_intent, {
              payment_method: {
                card: this.$data.card,
                billing_details: {
                  name: this.$data.credit_first_name + ' ' + this.$data.credit_last_name
                }
              }
            })
            const pm = response.setupIntent.payment_method
            const tokenizedData = {
              line_items: this.itemsToAdd,
              payment_details: {
                event_uuid: this.$route.params.event_uuid,
                participant_uuid: this.$route.params.attendee_uuid,
                first_name: this.$data.credit_first_name,
                last_name: this.$data.credit_last_name,
                address: this.$data.credit_address,
                city: this.$data.credit_city,
                state: this.$data.credit_state,
                zip: this.$data.credit_zip,
                country: this.$data.credit_country,
                stripePaymentId: pm,
                page_number: '',
                currentLanguage: this.$data.currentLanguage,
                event_identifier: this.event_identifier,
                widget_id: this.$data.payment_processor_widget_uuid,
                transaction_type: this.$data.payment_type,
                payment_type: this.$data.payment_type
              }
            }
            this.submitPaymentData(tokenizedData)
            this.$emit('action', { type: ActionType.SUCCESS, message: 'Transaction successfully added.' })
            Container.get(Notification).success('Transaction successfully added.')
          }
        }
      }

      // finally, if they say mark status as complete, send complete status.
      if (this.$data.makeStatusComplete) {
        await this.editRegistration()
      }
      // if they say to send an invoice email, send the email invoice.
      if (this.$data.sendInvoiceEmail) {
        await this.sendEmailToOneRegistration()
      }
    } catch (err) {
      Container.get(ErrorHandlerService).error(err)
    } finally {
      this.$data.loading = false
      this.$data.submitting = false
      this.cleanFormModel()
      this.onClose()

      this.fetchRegistration()
      this.fetchRegistrationAuditLog()

      const payload2 = {
        event_uuid: this.$data._event.uuid,
        participant_uuid: this.$route.params.attendee_uuid,
        queryParam: ''
      }
      this.$store.dispatch('registration/getRegistrationLeadsAppCodes', payload2)
    }
  }

  async sendEmailToOneRegistration () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        company_uuid: this.$route.params.company_uuid,
        registration_uuid: this.$route.params.attendee_uuid,
        email_name: 'invoice',
        data: {
          event_uuid: this.$route.params.event_uuid,
          company_uuid: this.$route.params.company_uuid,
          registration_uuid: this.$route.params.attendee_uuid,
          email_name: 'invoice',
          to: [this.registration.email],
          cc: [],
          bcc: ['receipts@gtrmeetings.com']
        }
      }
      await this.$store.dispatch('email/sendEmailToOneRegistration', payload)
      Container.get(Notification).success('Email successfully sent.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async editRegistration () {
    try {
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        registration_uuid: this.$route.params.attendee_uuid,
        data: {
          status: 'Complete'
        }
      }
      await this.$store.dispatch('registration/editRegistration', payload)
    } catch (e) {
      Container.get(ErrorHandlerService).error(e)
    }
  }

  private async fetchRegistrationAuditLog () {
    try {
      const payload = {
        event_uuid: this.$data._event.uuid,
        participant_uuid: this.$route.params.attendee_uuid,
        queryParam: ''
      }
      this.$store.dispatch('registration/getRegistrationAuditLog', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchRegistration () {
    try {
      const payload = {
        event_uuid: this.$data._event.uuid,
        participant_uuid: this.$route.params.attendee_uuid,
        queryParam: ''
      }
      const response = await this.$store.dispatch('registration/getRegistration', payload)
      if (response) {
        this.$data.attendee = this.registration.participant_data
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  clearCurrentTransaction () {
    this.$data.current_capture_transaction = null
  }

  get expirationYearsItems () {
    const arr: any = []
    let currentYear = parseInt(new Date().getFullYear().toString())
    arr.push(currentYear.toString())
    for (let i = 0; i <= 15; i++) {
      arr.push((currentYear++).toString())
    }
    return arr
  }

  async submitPaymentData (data) {
    try {
      this.$data.loading = true
      data.form = this.$data.lrorders_transaction ? 'lr_orders' : 'registration'
      data.make_complete = this.$data.lrorders_transaction ? 1 : 0
      await this.$store.dispatch('payment/createStripeTransaction', {
        event_uuid: this.$data._event.uuid,
        widget_id: this.$data.payment_processor_widget_uuid,
        participant_uuid: this.registration.uuid,
        payment_type: this.$data.payment_type,
        data: data
      })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private sleep (ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  cleanFormModel () {
    this.$data.payment_type = ''
    this.$data.amount = 0
    this.$data.current_capture_transaction = false
    this.$data.credit_card_number = ''
    this.$data.credit_cvv = ''
    this.$data.credit_first_name = ''
    this.$data.credit_last_name = ''
    this.$data.credit_address = ''
    this.$data.credit_city = ''
    this.$data.credit_state = ''
    this.$data.credit_zip = ''
    this.$data.credit_country = ''
    this.$data.credit_expiration_month = ''
    this.$data.credit_expiration_year = ''
    this.itemsToAdd = []
  }

  onClose () {
    this.cleanFormModel()
    this.$emit('close')
  }
}
