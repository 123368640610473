import { Component, Watch } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { mapState, mapGetters } from 'vuex'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import { SurveySummary } from '@/interfaces'

@Component({
  name: 'GtrActivityTab',
  computed: {
    ...mapState('registration', ['registrationEmailsSent']),
    ...mapState('sessions', ['sessionsByParticipant']),
    ...mapGetters('surveys', ['completedParticipantSurveys']),
    ...mapState('event', ['eventAllContent'])
  }
})
export default class GtrActivityTab extends GtrSuper {
  event_uuid = ''

  attendee_uuid = ''

  registrationEmailsSent!: Record<string, any>

  sessionsByParticipant!: Record<string, any>

  completedParticipantSurveys!: SurveySummary[];

  data () {
    return {
      event_all_content: null,
      payment_processor_currency: null,
      loading: false,
      display_emails: false,
      display_transactions: false,
      display_scans: false,
      display_sessions: false,
      display_surveys: false,
      page: 1,
      itemsPerPage: 10,
      itemsPerPageArray: [10, 20, 'All'],
      session_data: [],
      showedEmail: {
        to: '',
        cc: '',
        bcc: '',
        html: '',
        send_grid_activity: ''
      },
      dialog: false
    }
  }

  created () {
    this.event_uuid = this.$route.params.event_uuid
    this.attendee_uuid = this.$route.params.attendee_uuid
  }

  @Watch('eventAllContent', { immediate: true })
  onEventAllContentChange (value: any) {
    if (value) {
      this.$data.event_all_content = value
      this.$data.payment_processor_currency = this.$data.event_all_content.payment_processor_currency
    }
  }

  async mounted () {
    try {
      this.$data.loading = true
      await this.fetchRegistrationEmailsSent()
      await this.fetchSessionsByParticipant()
      if (this.sessions) {
        const sessions = this.sessions
        sessions.forEach(session => {
          this.fetchSessionTrackingData(session.session.uuid)
        })
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchRegistrationEmailsSent () {
    try {
      const payload = {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid,
        addedQueryParam: ''
      }
      await this.$store.dispatch('registration/getRegistrationEmailsSent', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchSessionsByParticipant () {
    try {
      const payload = {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid
      }
      await this.$store.dispatch('sessions/getSessionsByParticipant', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchSessionTrackingData (sessionUuid) {
    try {
      const payload = {
        event_uuid: this.event_uuid,
        session_uuid: sessionUuid
      }
      const response = await this.$store.dispatch('sessions/getSessionTrackingData', payload)
      this.$data.session_data.push(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  nextPage () {
    if (this.$data.page + 1 <= this.numberOfPages) {
      this.$data.page += 1
    }
  }

  formerPage () {
    if (this.$data.page - 1 >= 1) {
      this.$data.page -= 1
    }
  }

  updateItemsPerPage (number) {
    this.$data.itemsPerPage = number === 'All' ? this.filteredActivity.length : number
  }

  showViewEmailDialog (item) {
    this.$data.showedEmail = item || {}
    this.$data.dialog = !this.$data.dialog
  }

  get numberOfPages () {
    return Math.ceil(this.filteredActivity.length / this.$data.itemsPerPage)
  }

  get emails () {
    const participant_emails = this.registrationEmailsSent.data
    return participant_emails
  }

  get transactions () {
    const participant_transactions = this.$store.state.registration.registrationTransactions.data.filter(i => !i.parked)
    return participant_transactions
  }

  /*
   * For each session, find the participant and build object with session name, scan in and scan out times
   * Push built objects into array, each index will be one scan card on activity tab
   */
  get scans () {
    const sessions = this.$data.session_data
    const participant_scans: any = []
    if (sessions) {
      for (const session of sessions) {
        const scan_card_info = {
          session_name: session.name
        }
        const { scan_data } = session
        const attendee_scan = scan_data.filter((scan: any) => scan.participant_short.uuid === this.$data.attendee_uuid).sort()
        const attendee_scan_len = attendee_scan.length
        Object.assign(scan_card_info, {
          scan_in: attendee_scan[0].date_scanned,
          scan_out: attendee_scan[attendee_scan_len - 1].date_scanned
        })
        participant_scans.push(scan_card_info)
      }
    }
    return participant_scans
  }

  get sessions () {
    const participant_sessions = this.sessionsByParticipant
    return participant_sessions
  }

  get filteredActivity () {
    const filtered_activity: any [] = []
    const emails = this.emails
    const transactions = this.transactions
    const scans = this.scans
    const surveys = this.completedParticipantSurveys
    const sessions = this.sessions
    const displayEmails = this.$data.display_emails
    const displayTransactions = this.$data.display_transactions
    const displayScans = this.$data.display_scans
    const displaySessions = this.$data.display_sessions
    const displaySurveys = this.$data.display_surveys
    /*
     * Check boolean values for display
     * Add activity_type and push to filtered_activity
     */
    if (
      (emails && displayEmails) ||
      (emails && !displayEmails && !displayTransactions && !displayScans && !displaySessions && !displaySurveys)
    ) {
      for (let i = 0; i < emails.length; i++) {
        Object.assign(emails[i], {
          activity_type: 'email'
        })
        filtered_activity.push(emails[i])
      }
    }
    if (
      (transactions && displayTransactions) ||
      (transactions && !displayEmails && !displayTransactions && !displayScans && !displaySessions && !displaySurveys)
    ) {
      for (let i = 0; i < transactions.length; i++) {
        Object.assign(transactions[i], {
          activity_type: 'transaction'
        })
        filtered_activity.push(transactions[i])
      }
    }
    if (
      (scans && displayScans) ||
      (scans && !displayEmails && !displayTransactions && !displayScans && !displaySessions && !displaySurveys)
    ) {
      for (let i = 0; i < scans.length; i++) {
        let scanAlreadyExists = false
        for (let j = 0; j < filtered_activity.length; j++) {
          if (filtered_activity[j].scan_in === scans[i].scan_in || filtered_activity[j].scan_out === scans[i].scan_out) {
            scanAlreadyExists = true
          }
        }
        if (!scanAlreadyExists) {
          Object.assign(scans[i], {
            activity_type: 'scan'
          })
          filtered_activity.push(scans[i])
        }
      }
    }
    if (
      (sessions && displaySessions) ||
      (sessions && !displayEmails && !displayTransactions && !displayScans && !displaySessions && !displaySurveys)
    ) {
      for (let i = 0; i < sessions.length; i++) {
        let sessionAlreadyExists = false
        for (let j = 0; j < filtered_activity.length; j++) {
          if (filtered_activity[j].updated_at === sessions[i].updated_at) {
            sessionAlreadyExists = true
          }
        }
        if (!sessionAlreadyExists) {
          Object.assign(sessions[i], {
            activity_type: 'session'
          })
          filtered_activity.push(sessions[i])
        }
      }
    }
    if (
      (surveys && displaySurveys) ||
      (sessions && !displayEmails && !displayTransactions && !displayScans && !displaySessions && !displaySurveys)
    ) {
      for (let i = 0; i < surveys.length; i++) {
        Object.assign(surveys[i], {
          activity_type: 'survey',
          updated_at: surveys[i].filled_date
        })
        filtered_activity.push(surveys[i])
      }
    }
    /*
     * Sort by most recent date
     */
    filtered_activity.sort(function (a, b) {
      return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
    })

    return filtered_activity
  }

  get isTrackEnabled (): boolean {
    return this.$store.state.event?.eventModules?.SESSION_TRACKING.enabled ?? false
  }

  get isSurveysEnabled (): boolean {
    return this.$store.state.event?.eventModules?.SURVEYS?.enabled ?? false
  }

  /**
   * takes a date as a string and returns a date time formatted to local timezone.
   *
   * @param      {string}  time    The time
   * @return     {string}  { the formatted datetime or an empty string }
   */
  private formatDateTime (time: string): string {
    try {
      if (!time) return ''
      const date = new Date(time)
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
      return date.toLocaleString()
    } catch (err) {
      Container.get(Notification).error('formateDateTime: Error formatting time')
      return ''
    }
  }
}
